import React from 'react';

const About = () => {
  return (
    <div className='aboutPage r-background'>
        <div className='aboutHeader'>
            <h1 className='r-aboutTitles'>ABOUT THE CHAMPION'S ARENA HUB</h1>
        </div>
        <div className='aboutContentCont'>

            <p>
            As passionate Gala games enthusiasts, we decided to develop this helpful Champion's Arena website. The aim was to create a central resource for fans of all levels - from newcomers to seasoned players.<br />
            </p>

            <p className='centered'>~~~~~</p>
            
            <p>
            Recognizing the lack of a centralized hub for Champion's Arena, we saw the opportunity to fill this gap. While there were forums and communities scattered around, there wasn't a single go-to place for comprehensive information, strategy discussions, and updates.<br />
            </p>

            <p className='centered'>~~~~~</p>

            <p>
            Thus, the Champion's Arena Hub was born. A one-stop-shop for all things related to the game, where players can find the latest news, share strategies, and connect with fellow enthusiasts. The hub is still evolving, and I hope to add more interactive features like forums in the future.<br />
            </p>

            <p className='centered'>~~~~~</p>

            <p>
            It's not just about information; it's about building a community. This hub aims to be a welcoming space for all Champion's Arena players, where everyone, regardless of their background or skill level, can share their passion for the game.<br />
            </p>

            <p className='centered'>~~~~~</p>

            <p>
            My heartfelt thanks go out to everyone who contributed to this project, especially Classy Games, Hydra, and Scuzzy, for their efforts in gathering and compiling information. Your support and contributions have been invaluable in creating this hub.<br /><br />
            </p>

            <p className='centered'>
            Here's to the growth and success of the Champion's Arena Hub!<br/><br/> 
            </p>

            <p className='centered'>~~~~~</p>

            <p>
            Sincerely, thank you all for helping foster this amazing community,
            </p>
            <p id='sig'>HammerHammond23</p>

        </div>

    </div>
  );
}

export default About;
