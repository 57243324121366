const MenuItems = [
  {
    title: 'Estates',
    path: '/estates',
    cName: 'dropdown-link'
  },
  {
    title: 'Arena',
    path: '/arena',
    cName: 'dropdown-link'
  },
  {
    title: 'Campaign',
    path: '/campaign',
    cName: 'dropdown-link'
  },
  {
    title: 'Mayhem',
    path: '/mayhem',
    cName: 'dropdown-link'
  },
  {
    title: 'Tower of Trials',
    path: '/tower-of-trials',
    cName: 'dropdown-link'
  }
];
const MenuItems2 = [
  {
    title: 'Cash Shop',
    path: '/cash-shop',
    cName: 'dropdown-link'
  },
  {
    title: 'Creators',
    path: '/creators',
    cName: 'dropdown-link'
  }
]

export { MenuItems, MenuItems2 }